import React, { useState, useRef, useEffect } from "react"

import { Map, Marker } from "react-map-gl"
import { SearchBox } from "@mapbox/search-js-react"
import mapboxgl from "mapbox-gl"
import homeMarker from "../assets/img/general/homemarker.png"
import deliverymanMarker from "../assets/img/general/helmet.png"
import markerIcon from "../assets/img/general/citymarker.png"

// Styles
import "./MapsCF.scss"
import { getAddressSuggestionsByName } from "../helpers/AddressessTools"
import CompanyContext from "../contexts/CompanyContext"

const MAPBOX_KEY = process.env.REACT_APP_MAPBOX_KEY

const MapsCF = ({
  initialViewState,
  mapLocation = null,
  deliverymanLocation = null,
  companyLocation = null,
  homeLocation = null,
  onMoveMap,
  mapHandlers = true,
}) => {
  const [viewState, setViewState] = useState(initialViewState)
  const mapRef = useRef(null)

  useEffect(() => {
    setViewState((prevState) => ({
      ...prevState,
      ...initialViewState,
    }))
  }, [initialViewState])

  useEffect(() => {
    if (mapLocation) {
      setViewState((prevState) => ({
        ...prevState,
        longitude: mapLocation.longitude,
        latitude: mapLocation.latitude,
      }))
    }
  }, [mapLocation])

  const handleZoomIn = () => {
    setViewState((prevState) => ({
      ...prevState,
      zoom: Math.min(prevState.zoom + 0.3, 20),
    }))
  }

  const handleZoomOut = () => {
    setViewState((prevState) => ({
      ...prevState,
      zoom: Math.max(prevState.zoom - 0.3, 1),
    }))
  }

  return (
    <Map
      mapLib={mapboxgl}
      mapboxAccessToken={MAPBOX_KEY}
      {...viewState}
      dragRotate={mapHandlers}
      scrollZoom={mapHandlers}
      boxZoom={mapHandlers}
      interactive={mapHandlers}
      style={{ width: "100vw", height: "100vh" }}
      mapStyle="mapbox://styles/mapbox/streets-v11"
      onMove={onMoveMap}
      padding={!!homeLocation ? { bottom: 340 } : {}}
      ref={mapRef}
    >
      {!!mapHandlers ? (
        <div id="zoom-buttons">
          <button id="zoom-in" onClick={handleZoomIn}>
            +
          </button>
          <button id="zoom-out" onClick={handleZoomOut}>
            -
          </button>
        </div>
      ) : (
        ""
      )}
      {/* Pino do centro do mapa */}
      {!!mapLocation ? (
        <Marker latitude={mapLocation.latitude} longitude={mapLocation.longitude} draggable={false}>
          <div className="modal-map-address--body-marker-icon">
            <img alt="Pino central" src={markerIcon} />
          </div>
        </Marker>
      ) : (
        ""
      )}

      {/* Entregador */}
      {!!deliverymanLocation ? (
        <Marker latitude={deliverymanLocation?.latitude || 0} longitude={deliverymanLocation?.longitude || 0} anchor="center">
          <div className="order-progress--body--bottom-home-marker">
            <img alt="Entregador" src={deliverymanMarker} />
          </div>
        </Marker>
      ) : (
        ""
      )}

      {/* Da casa do cliente */}
      {!!homeLocation ? (
        <Marker latitude={homeLocation?.latitude || 0} longitude={homeLocation?.longitude || 0} anchor="center">
          <div className="order-progress--body--bottom-home-marker">
            <img alt="Casa" src={homeMarker} />
          </div>
        </Marker>
      ) : (
        ""
      )}

      {/* Pino da loja */}
      {!!companyLocation ? (
        <Marker latitude={companyLocation?.latitude || 0} longitude={companyLocation?.longitude || 0} anchor="center">
          <div className="order-progress--body--bottom-home-marker">
            <img alt="Loja" src={homeMarker} />
          </div>
        </Marker>
      ) : (
        ""
      )}
    </Map>
  )
}

const PlaceMap = ({ onPlaceSelected, showMap, setShowGetLocationButton }) => {
  const { company } = React.useContext(CompanyContext)
  const [placemapText, setPlacemapText] = React.useState("")
  const [onFocus, setOnFocus] = React.useState(false)
  const [fetchingSuggestions, setFetchingSuggestions] = React.useState(false)
  const [suggestions, setSuggestions] = React.useState(false)
  const changingTimeoutRef = React.useRef(() => {})

  const onChangePlaceMap = (placemap) => {
    setFetchingSuggestions(true)
    setPlacemapText(placemap)
    clearTimeout(changingTimeoutRef.current)
    if (placemap.trim().length > 0) {
      changingTimeoutRef.current = setTimeout(() => {
        onGetAddressSuggestions(placemap)
      }, 1000)
    } else {
      setFetchingSuggestions(false)
    }
  }

  const onGetAddressSuggestions = async (placemap) => {
    try {
      const fetchedSuggestions = await getAddressSuggestionsByName(placemap, company?.company_address[0]?.geo_coordinates)
      if (!!fetchedSuggestions) {
        setSuggestions(fetchedSuggestions)
      }
    } catch (error) {
      console.error("Falha ao consultar sugestões", error)
    }
    setFetchingSuggestions(false)
  }

  const onSelectedSuggestion = (suggestionSelected) => {
    onPlaceSelected(suggestionSelected)
  }

  return (
    <>
      <div style={{ width: "100%" }}>
        <SearchBox
          accessToken={process.env.REACT_APP_MAPBOX_KEY}
          onSuggest={(e) => {
            setShowGetLocationButton(true)
          }}
          onRetrieve={(e) => {
            if (!!e) {
              if (!!e.features) {
                let suggestionSelected = e.features[0]

                if (!!suggestionSelected) {
                  const place = {
                    street: suggestionSelected.properties.context.street.name,
                    neighbourhood: suggestionSelected.properties.context.neighborhood?.name,
                    zipcode: suggestionSelected.properties.context.postcode?.name,
                    city: suggestionSelected.properties.context.context?.place?.name,
                    state: suggestionSelected.properties.context.context?.region?.name,
                    coordinates: suggestionSelected.properties.coordinates,
                    number: suggestionSelected.properties.context.context?.address?.address_number,
                  }

                  onPlaceSelected(place, suggestionSelected)
                }
              }
            }
          }}
          options={{
            types: ["address", "street"],
            language: "pt",
            country: "BR",
            proximity: [company?.company_address[0]?.geo_coordinates[1], company?.company_address[0]?.geo_coordinates[0]],
          }}
          theme={{
            variables: {
              border: "none !important",
              padding: "20px",
              paddingFooterLabel: "10px",
              paddingModal: "20px",
            },
          }}
        />
      </div>
    </>
  )
}

export { MapsCF, PlaceMap }
